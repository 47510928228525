@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply p-4
    }

        button {
            @apply px-4 py-2 text-white bg-ihs-green hover:bg-ihs-green-shade-700 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
        }

    html {
        font-family: 'Be Vietnam Pro', sans-serif;
    }
}

body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

/* Standard syntax */
::placeholder {
    color: red;
    opacity: 1;
}

/* Webkit (Safari, Chrome) */
::-webkit-input-placeholder {
    color: red;
    opacity: 1;
}

/* Edge */
::-ms-input-placeholder {
    color: red;
    opacity: 1;
}

.error-message {
    /* animation: flyIn .3s ease-in-out; */
    transition: transform 2s ease-in-out;
}

.error-message.fade-in {
    opacity: 1;
    transform: translateY(0px);
    transition: transform 2s ease-in-out;
}

.error-message.fade-out {
    opacity: 0;
    transform: translateY(-10px);
}

@keyframes flyIn-y {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width:32.5em) {}